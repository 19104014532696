.sidebar{
  height: 100vh;
  position: sticky;
  top:0;
  /* min-width: 2girem; */

}
.sidebar-container {
  .nav {
    --bs-nav-link-color: #000;

    .nav-item{
      .active{
        background-color: #274da5;
        /* color: blue ; */
        cursor: pointer;
      }
    }
  }
}

.sidebar-container .nav.nav-pills {
  --bs-nav-pills-link-active-bg: #274da5;
}

.sidebar-container .nav.nav-pills svg path {
  stroke: currentColor;
}

.sidebar-container .nav svg path {
  stroke: currentColor;
}

.sidebar-container .avatar {
  width: 50px;
  height: 50px;
  // background-color: #1c3879;
  color: #fff;

  img{
    height: auto;//40px;
    width: 80px;
  }
}

.sidebar-container .email {
  font-size: 0.7rem;
}


.selectedClass{
  width: 18rem;
}

.avatarProp img {
  flex: initial; /* Override flex property */
  object-fit: cover; /* Override object-cover property */
  width: 50px; /* Override w-full property */
  height: 500px; /* Override h-full property */
  transition: none; /* Override transition-opacity property */
  opacity: 1; /* Override opacity-0 property */
}