.planSelectDiv {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
  .dashed {
    border-bottom: 1px dashed #d9d9d9;
    .heading {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }
    p {
      color: #595959;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin: 0;
    }
  }
}

.nol {
  color: #000000;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  font-family: Inter, "sans-serif";
  font-weight: 600;
}
