@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&family=Roboto:wght@300;400;500;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.Toastify__toast--error {
    /* border: 1px solid #EB5757; */
     border-radius: 5px !important;
    background: #FAE1E2 !important;
    color: #7E1001;
}
.Toastify__toast--error::after {
  /* content : "X"; */
  position: absolute;
  color: #7E1001;
  font-size: 15px;
  font-weight: 700;
  left:265px;
  padding-top: 14px !important;
}
.Toastify__toast--error::before {
  /* content: url("../assets/images/svg/errorToast.svg");  */
  position:relative; 
  z-index:100000;
  left:12px;
  top:6px;
  color: #7E1001;
}
.Toastify__toast--success {
  /* border: 1px solid #3A9EA5 !important; */
   border-radius: 5px !important;
  background: #F0F9FA !important;
}
.Toastify__toast--success::before {
  /* content: url("../assets/images/svg/successToast.svg");  */
  position:relative; 
  z-index:100000;
  left:12px;
  top:6px;
}
.Toastify__toast--success::after {
  content : "X"; 
  position: absolute;
  color: #148B47;
  font-size: 15px;
  font-weight: 700;
  left:265px;
  padding-top: 14px !important;
}
.Toastify__toast--warning {
  /* border: 1px solid #E78326  !important; */
   border-radius: 5px !important;
  background: #FADFC5 !important;
}
.Toastify__toast--warning::before {
  /* content: url("../assets/images/svg/warnToast.svg"), */
  position:relative; 
  z-index:100000;
  left:12px;
  top:6px;
}  
.Toastify__toast--warning::after {
  /* content : url('../assets/images/svg/closeToast.svg'); */
  position: absolute;
  color: #E78326;
  font-size: 15px;
  font-weight: 700;
  left:265px;
  padding-top: 14px !important;
}
.Toastify__toast-body {
  color: #444C63    ;
  font-size: 16px;
  padding-left: 20px;
  line-height: 20px;
  padding: 0px;
  padding-top: 25px;
  width: 100%;
  font-weight: 400;
  margin-left: 25px !important;
} 
.Toastify__toast > button>  svg {
    display: none;
}