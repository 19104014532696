/* style.css */
.dashboard-visible {
    display: block;
  }
  
  .error-desktop {
    display: none;
    text-align: center;
    padding: 2rem;
    font-size: 1.2em;
    color: red;
  }
  
  @media (max-width: 768px) {
    .dashboard-visible {
      display: none;
    }
  
    .error-desktop {
      display: block;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
  }
  