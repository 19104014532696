.loader-container {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.loader {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #000;
  border-radius: 50%;
  animation: spin 1.5s infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}