:where(.css-dev-only-do-not-override-2q8sxy).ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #fcece1;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #148b47;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: #148b47;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: #fcece1;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #fff;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: #fff;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-steps
  .ant-steps-item-wait
  .ant-steps-item-icon {
  background-color: #fff;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon {
  background-color: #148b47;
  border-color: #148b47;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-badge .ant-badge-count, :where(.css-dev-only-do-not-override-2q8sxy).ant-badge .ant-badge-dot, :where(.css-dev-only-do-not-override-2q8sxy).ant-badge .ant-scroll-number-custom-component{
    background-color: #148b47;
    color: #fff;
    border-radius: 50%;
    padding: 3px;
    font-size: 10px;
}
.anticon {
  color: #fff;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #148b47;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-steps
  .ant-steps-item-tail::after {
  background-color: #148b47 !important;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon {
  background-color: #fcece1;
  border-color: #fcece1;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #000;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-avatar {
  background-color: #fff;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-badge .ant-badge-count {
  background-color: #148b47;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-btn-primary:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  background-color: #fff;
  color: #1c3879;
  border: 1px solid #1c3879;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  background-color: #1c3879;
  color: #fff;
  border: 1px solid #1c3879;
}
.form-check-input:checked {
  background-color: #1c3879 !important;
  border-color: #1c3879 !important;
}
.check {
  font-size: 12px;
  color: #fff;
  background-color: #148b47;
  border-color: #148b47;
  border-radius: 50%;
  padding: 3px;
}
.check-green {
    color: green; // Change this to your desired green color
  }
  
  .check-red {
    color: red; // Change this to your desired red color
  }
.checkLabel {
  color: #262626;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
  p {
    color: #8c8c8c;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
.textStyle {
  color: #000;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
}
.signUpParagraph {
  font-size: 16px;
  font-style: normal;
  color: #595959;
  font-weight: 400;
  line-height: 20px;
}
.verifyStyle {
  display: flex;
  position: relative;
  width: 50px;
  height: 50px;
}
.middle {
  align-self: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: #148b47;
}

.top-right {
  position: absolute;
  top: 0;
  right: 0;
}
.form-control {
  width: 360px;
  height: 56px !important;
  border-radius: 8px;
  background-color: #fbfbfb;
}

.form-control[type="name"]:focus {
  outline: none !important;
  border: none !important;
}

.form-control[type="name"] {
  font-weight: 600;
  font-size: 16px;
  background-color: #fbfbfb;
  color: #262626;
}

.form-control[type="email"]:focus {
  outline: none !important;
  border: none !important;
}

.form-control[type="email"] {
  font-weight: 600;
  font-size: 16px;
  background-color: #fbfbfb;
  color: #262626;
}

.form-control[type="email"]:focus {
  outline: none !important;
  border: none !important;
}

.form-control[type="email"] {
  font-weight: 600;
  font-size: 16px;
  background-color: #fbfbfb;
  color: #262626;
}
.react-tel-input .form-control {
  width: 100% !important;
  border: 1px solid #dee2e6 !important;
}
.react-tel-input .flag-dropdown {
  border: 1px solid #dee2e6 !important;
  background-color: #fff !important;
}
.uploadBtn {
  color: #1c3879;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background-color: #eaeffa;
  border: 1px solid #eaeffa;
  border-radius: 8px;
}
.borderOtp {
  flex-direction: row;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fbfbfb;
  //   width: "40px";
  //   height: "40px";
  //   text-align: "center";
  //   margin-right: "5px";
}

.stepsBtn {
  height: 56px;
  padding: 16px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #1c3879;
  border: 1px solid #1c3879;
}
.contentStyle{

  width: 360px;
    text-align: center;
    // color: /* replace with the actual color variable or value */;
    // border-radius: /* replace with the actual border radius variable or value */;
    margin-top: 16px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: "center", */ /* Uncomment if needed */
    height: 100%;
}


.white-text-steps{
  color: white;
}

.active-text-steps{
  color: #F6C4A2;
}

.visible-password-icon {
  position: relative;
  bottom: 40px;
  left: 90%;
  top: 5%;
  color: #A1A5B7;
}