// .login-container {
//     width: 100vw;
//     background-color: #F5F5F5;
//     display: flex;
//     height: 100vh;
//     flex-direction: row;
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 1;

//     .login-image {
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         // height: 100vh;
//     }

//     .login-form-container {
//         width: 100%;
//         height: 100%;
//         background-color: #fff;
//         // margin: 4rem;
//         border-radius: 24px;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;

//         // font-family: inter;
//         h1 {
//             font-size: 32px;
//             font-weight: 700;
//             color: #181C32;
//             text-align: center;
//         }

//         p {
//             color: #A1A5B7;
//             font-size: 14px;
//         }

//         .login-btns {
//             background-color: #fff;
//             font-size: 18px;
//             font-weight: 500;
//             border-radius: 6px;
//             padding: 10px 20px;
//             margin-left: 10px;
//             border: 1px solid #EFF2F5;
//             color: #5E6278;
//         }

//         .withEmail {
//             margin-top: 20px;
//         }

//         .loginform-input {
//             width: 450px;
//             padding: 10px 16px;
//             border: 1px solid #E4E6EF;
//             border-radius: 6px;
//             position: relative;
//         }
//         .loginform-input[type=text] {
//             font-size: 18px;
//         }

//         .loginform-input[type=number] {
//             font-size: 18px;
//         }
//         .loginform-input::placeholder {
//             font-weight: 500;
//             color: #A1A5B7;
//         }

//         input::-webkit-outer-spin-button,
//         input::-webkit-inner-spin-button {
//             -webkit-appearance: none;
//             margin: 0;
//         }

//         .loginform-input:focus {
//             outline: none;
//         }

//         .input-form-container {
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             align-items: left;
//             gap: 1rem;

//             .visible-password-icon {
//                 position: relative;
//                 bottom: 47px;
//                 left: 200px;
//                 color: #A1A5B7;
//             }
//         }

//         .forget-password-div {
//             text-align: center;

//             .forget-password {
//                 font-size: 18px;
//                 color: #A1A5B7;

//                 a {
//                     color: #3E97FF;
//                     font-weight: 500;
//                     cursor: pointer;
//                     text-decoration: none;
//                 }
//             }
//         }

//         .otp-btn {
//             width: 450px;
//             background-color: #3E97FF;
//             color: #fff;
//             padding: 10px 20px;
//             text-align: center;
//             border-radius: 6px;
//             font-weight: 500;
//             font-size: 18px;
//             margin-top: 15px;
//             border: none;
//             cursor: pointer;
//         }

//         .btn-disabled {
//             width: 450px;
//             background-color: #d9d9d9;
//             color: #fff;
//             padding: 10px 20px;
//             text-align: center;
//             border-radius: 6px;
//             font-weight: 500;
//             font-size: 18px;
//             margin-top: 30px;
//             border: none;
//         }

//         .login-link {
//             margin-top: 20px;
//             color: #A1A5B7;
//             font-size: 18px;

//             .signinNavigationLink {
//                 color: #3E97FF;
//                 font-weight: 500;
//                 cursor: pointer;
//             }
//         }

//         .login-link a {
//             font-weight: 500;
//             text-decoration: none;
//         }
//     }
// }

// @media (max-width: 1400px) {
//     .login-container {
//         .login-form-container {
//             h1 {
//                 margin-bottom: 3rem;
//             }
//         }
//     }
// }


// @media (max-width: 550px) {
//     .login-container .login-form-container .input-form-container .visible-password-icon {
//         position: relative;
//         bottom: 47px;
//         left: 150px!important;
//         color: #A1A5B7;
//     }
// }

.login-main {
    width: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    font-family: 'Inter', sans-serif;
    height: 100vh;
}

.img-div {
    width: 65%;
    height: 880px;
}

.clickME {
    color: var(--bs-link-color);
    text-decoration: underline;
    cursor: pointer;
}

.login-img {
    width: calc(100% - 125px);
    height: 1080px;
    position: relative;
    background-size: contain;
    object-fit: cover;
}

.img-title {
    position: absolute;
    top: 3rem;
    left: 5rem;
}

.img-title h1 {
    color: #fff;
    width: 75% !important;
    font-family: 'Inter', sans-serif;
    text-align: center;
    letter-spacing: 1px;
    margin-top: 50px;
    font-family: 'inter-light', sans-serif !important;
}

.resendOtp {
    text-align: right;
    color: #1C3879 !important;
    font-weight: bold;
    cursor: pointer;
}

.resendOtpAgain {
    text-align: right;
    font-weight: bold;
}

.img-title p {
    color: #fff;
    width: 400px;
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    text-align: center;
    margin-top: -15px;
}

.back-login {
    font-size: 16px;
    color: #1C3879;
    font-weight: 600;
    cursor: pointer;
}

.img-tagline {
    color: #fff;
    width: 75% !important;
    font-family: vector;
    text-align: left !important;
    letter-spacing: 3px;
    // padding-top: 675px !important;
    padding-top: calc(96vh) !important;
    /* margin-left: 50px; */
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    /* margin-top: 650px!important; */
}


.other-img {
    width: calc(100% - 100px);
    height: 880px;
    position: relative;
    background-size: contain;
    transition: opacity 1s linear;
    animation: slide 2s ease-in-out infinite;

    /* object-fit: cover; */
}

.img-head {
    position: absolute;
    color: #fff;
}

.login-form {
    width: calc(30% - 70px);
    ;
    display: flex;
    flex-direction: column;
    /* padding-left: 9rem; */
    justify-content: center;
    align-items: center;
    /* color: #595959; */
}

.login-form h1 {
    width: 360px;
    font-size: 32px;
    font-weight: 700;
}

.login-form p {
    color: #595959;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    width: 360px;
    word-spacing: 3px;
}

.login-para {
    color: #595959;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    width: 360px !important;
    word-spacing: 3px;
}

.form-control {
    width: 360px;
    height: 56px !important;
    border-radius: 8px;
    background-color: #FBFBFB;
}

.form-control[type='email']:focus {
    outline: none !important;
    border: none !important;
}

.form-control[type=email] {
    font-weight: 600;
    font-size: 16px;
    background-color: #FBFBFB;
    color: #262626;
}

.form-control[type=password] {
    font-weight: 600;
    font-size: 16px;
    background-color: #FBFBFB;
}


.form-label {
    font-weight: 400;
}

.link {
    text-decoration: underline;
}

.btn-login {
    width: 360px;
    height: 56px;
    border: none;
    background-color: #1C3879;
    color: #fff;
    border-radius: 8px;
    padding: 16px;
    font-weight: 600;
    margin-top: 20px;
}

.btn-disabled {
    width: 360px;
    height: 56px;
    border: none;
    background-color: #D9D9D9;
    color: #fff;
    border-radius: 8px;
    padding: 16px;
    font-weight: 600;
    margin-top: 20px;
}

.back-para {
    text-align: center;
    margin-top: 20px;
    font-weight: 600;
    font-size: 14px;
}

.back-para a {
    text-decoration: none;
    color: #1C3879 !important;
    font-weight: 600;
    cursor: pointer;
}

.form-control::-webkit-outer-spin-button,
.form-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.form-control[type=number] {
    -moz-appearance: textfield;
}

.validation-types {
    display: flex;
    text-align: left;
    gap: 1rem;
}

.validation {
    width: 22%;
}

.validation p {
    font-size: 16px;
    font-weight: 600;
    width: 80%;
    text-align: center;
}

.validation-signs {
    padding-left: 3rem;
    img{
        margin-top: -1rem;
    }
}

.visible-password-icon {
    position: relative;
    bottom: 40px;
    left: 90%;
    top: 5%;
    color: #A1A5B7;
}

@media(max-width: 760px) {
    .login-img {
        display: none;
    }
    .img-title h2 {
        display: none;
    }

    .img-div {
        width: 40%;
    }

    .other-img {
        display: none;
    }
}

@media(max-width: 1650px) {
    .img-title h2 {
        width: 70% !important;
        font-size: 32px;
    }
    .img-tagline{
        margin-top: -14rem;
    }
}

@media(max-width: 1400px) {
    .img-title h2 {
        width: 52.5% !important;
        font-size: 28px;
        margin-top: -16rem;
        
    }
}

@media(max-width: 1100px) {
    .img-title h2 {
        width: 55% !important;
        font-size: 28px;
        
    }
}

@media(max-width: 1000px) {
    .img-title h2 {
        width: 45% !important;
        font-size: 24px;

    }
}